<template lang="pug">
.compliance
	Menu(title="Multi Image Compliance")

	.container
		.compliance__tabs
			Tabs(type="gray")
				TabItem(title="Scorecard")
		.compliance__section
			.compliance__subtitle Latest One Day Score
			.flex.justify-between
				.compliance__filters.flex
					.compliance__filter(
						v-for="filter of filters"
						:key="filter.value"
						v-model="activeFilter.value"
						:class="{'--active': filter.value === activeFilter.value}"
						@click="changeFilter(filter)"
					)
						| {{ filter.title }}
				GoalFilter(
					:goalsBelowCheckbox="goalsBelowCheckbox"
					:goalsAboveCheckbox="goalsAboveCheckbox"
					@change="changeHandler"
				)
			template(v-if="isFetchProcessing")
				.mt-32
					PuSkeleton(height="20px" color="#E0E0E0")
				.mt-16
					PuSkeleton(height="20px" color="#E0E0E0")
				.mt-16
					PuSkeleton(height="20px" color="#E0E0E0")
				.mt-16
					PuSkeleton(height="20px" color="#E0E0E0")
			template(v-else-if="products")
				Table.compliance__table(v-bind="tableOptions")
					template(#percent="{ item, index, value }")
						template(v-if="value && isActiveGoal(value)")
							.goal(:class="`--${getGoalClass(value)}`")
								| {{ value }}%
						template(v-else)
							.goal.--none
								| х
			template(v-else)
				| Чтобы данные отобразились в дашборде, выберите значения в фильтрах и нажмите "Apply filter”

</template>

<script>
import Menu from '@/components/Menu/Menu.vue'
import Tabs from '@/components/Tabs/Tabs.vue'
import TabItem from '@/components/Tabs/TabItem.vue'
import Table from '@/components/Table/Table.vue'
import GoalFilter from '@/components/Nestle/GoalFilter/GoalFilter'
import FilterInLocalStorage from '@/helpers/LocalStorage/Filter'

export default {
	components: {
		Menu,
		Tabs,
		TabItem,
		Table,
		GoalFilter,
		FilterInLocalStorage,
	},
	props: {
		date: {
			type: Object,
			default: () => {},
		},
	},
	data() {
		return {
			goalsBelowCheckbox: {
				high: true,
				medium: true,
				light: true,
			},
			goalsAboveCheckbox: {
				light: true,
				medium: true,
				high: true,
			},
			activeFilter: {
				value: 1,
				title: 'Retailers',
				group: 'retailers',
			},
			filters: [
				{
					value: 1,
					title: 'Retailers',
					group: 'retailers',
				},
				{
					value: 2,
					title: 'Categories',
					group: 'categories',
				},
				{
					value: 3,
					title: 'Brands',
					group: 'brands',
				}
			],
			products: null,
			isFetchProcessing: true,
		}
	},
	computed: {
		tableData() {
			let summary = [{ ...this.products.summary, name: 'All Products'}]
			return summary.concat( this.products.items)
		},
		tableColumns() {
			return 	[
				{
					title: '',
					width: 100,
					value: item => {
						return item.name
					},
				},
				{
					title: 'Overall Image Accuracy',
					width: 110,
					slot: 'percent',
					value: item => {
						return Math.round(item.overall.passed / item.overall.total * 100)
					},
				},
				{
					title: 'Main Image Accuracy',
					width: 110,
					slot: 'percent',
					value: item => {
						return Math.round(item.main.passed / item.main.total * 100)
					},
				},
				{
					title: 'Side Of Pack',
					width: 110,
					slot: 'percent',
					value: item => {
						return Math.round(item.side_of_pack.passed / item.side_of_pack.total * 100)

					},
				},
				{
					title: 'Out Of Pack',
					width: 110,
					slot: 'percent',
					value: item => {
						return Math.round(item.out_of_pack.passed / item.out_of_pack.total * 100)
					},
				},
				{
					title: 'Ingredient List',
					width: 110,
					slot: 'percent',
					value: item => {
						return Math.round(item.ingredient.passed / item.ingredient.total * 100)
					},
				},
				{
					title: 'Nutritional Panel',
					width: 110,
					slot: 'percent',
					value: item => {
						return Math.round(item.nutritional.passed / item.nutritional.total * 100)
					},
				},
				{
					title: 'Other Images',
					width: 110,
					slot: 'percent',
					value: item => {
						return Math.round(item.other.passed / item.other.total * 100)
					},
				},
			]
		},
		tableOptions() {
			return {
				// sort: {field: 'name', order: 'desc'},
				columns: this.tableColumns,
				data: this.tableData,
			}
		},
	},
	mounted() {
		const params = this.filterParamsFromLocalStorage()
		this.fetch(params)
	},
	methods: {
		async fetch(params) {
			try {
				this.isFetchProcessing = true
				const resp = await this.$api.nestle.getImageCompliance(params)
				if (resp) {
					this.products = resp
				}
			} catch (error) {
				console.log(error)
			} finally {
				this.isFetchProcessing = false
			}
		},
		exportHandler() {
			console.log('exportHandler')
		},
		changeFilter(item) {
			this.activeFilter = item
			const params = this.filterParamsFromLocalStorage()
			this.fetch(params)
		},
		getGoalClass(val) {
			let className
			if (val > 90) {
				className = 'above-high'
			} else if (val >= 85) {
				className = 'above-medium'
			} else if (val > 75) {
				className = 'above-light'
			} else if (val > 50) {
				className = 'below-light'
			} else if (val > 20) {
				className = 'below-medium'
			} else {
				className = 'below-high'
			}
			return className
		},
		isActiveGoal(val) {
			let params = this.getGoalClass(val).split('-')
			return params[0] === 'below' ? this.goalsBelowCheckbox[params[1]] : this.goalsAboveCheckbox[params[1]]
		},
		changeHandler({item, type}) {
			if (type === 'below') {
				this.goalsBelowCheckbox[item.title] = !item.value
			} else {
				this.goalsAboveCheckbox[item.title] = !item.value
			}
		},

		filterParamsFromLocalStorage() {
			const currentLSState = FilterInLocalStorage.get()
			return {
				category: this.group,
				stores: Object.keys(currentLSState.stores).join(',') || null,
				categories: Object.keys(currentLSState.categories).join(',') || null,
				brands: Object.keys(currentLSState.brands).join(',') || null,
				regions: Object.keys(currentLSState.regions).join(',') || null,
				dateFrom: this.$utils.format(this.date.dateFrom) || null,
				dateTo: this.$utils.format(this.date.dateTo) || null,
			}
		},

	},
}
</script>

<style lang="scss" scoped>
.compliance {
	&__nav {
		width: 190px;
	}
	&__date {
		width: 100px;
		color: color(white);
		cursor: pointer;
	}
	&__tabs {
		margin: 36px 0;
	}

	&__subtitle {
		font-size: 16px;
		font-weight: 600;
	}
	&__filters {
		margin-top: 20px;
	}

	&__filter {
		padding: 4px 8px;
		border: 1px solid color(gray-300);
		cursor: pointer;

		&.--active {
			background-color: color(gray-200);
		}
	}
	&__section {
		padding: 20px;
		background-color: color(white);
		border-radius: 4px;
	}

	&__table {
		margin-top: 36px;

		tbody {
			tr {
				&:first-of-type {
					height: 80px;
				}
			}
		}
	}

	.goal {
		padding: 10px 20px;
		border-radius: 4px;
		text-align: center;
		font-weight: 600;

		&.--none {
			background-color: color(gray-200);
			color: color(gray-700);
		}

		&.--above-high {
			background-color: color(goal-above-high);
		}
		&.--above-medium {
			background-color: color(goal-above-medium);
		}
		&.--above-light {
			background-color: color(goal-above-light);
		}
		&.--below-light {
			background-color: color(goal-below-light);
		}
		&.--below-medium {
			background-color: color(goal-below-medium);
		}
		&.--below-high {
			background-color: color(goal-below-high);
		}
	}
}
</style>
